.ribbon {
  font-size: 12px;
  color: #fff;
  width: 48px;
  text-align: center;
}
.ribbon {
  --f: 0.4em; /* control the folded part */
  --r: 0.8em; /* control the cutout */

  position: absolute;
  left: 8px;
  top: calc(-1.25 * var(--f));
  padding: 0.4em;
  border: solid #0000;
  border-width: 0 calc(2 * var(--f)) var(--r) 0;
  background: radial-gradient(50% 100% at bottom, #0005 98%, #0000 101%) 100% 0 /
    calc(2 * var(--f)) var(--f) no-repeat border-box;
  background-color: #cc333f;
  border-radius: var(--f) var(--f) 0 0;
  clip-path: polygon(
    100% 0,
    0 0,
    0 calc(100% - var(--r)),
    calc(50% - var(--f)) 100%,
    calc(100% - 2 * var(--f)) calc(100% - var(--r)),
    calc(100% - 2 * var(--f)) var(--f),
    100% var(--f)
  );
}

.btn-no-focus:focus {
  outline: none;
}
